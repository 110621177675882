<template>
    <div class="page-content lg:p-6">
        <h3 class="text-center mb-5">{{ page?.name }}</h3>
        <div class="w-full" v-if="loading">
            <Skeleton shape="rectangle" width="100%" height="1.4rem" class="mb-3" v-for="n in 8" :key="n" />
        </div>
        <template v-if="!loading && page && page?.content.page_type === 'custom'">
            <div class="grid">
                <template v-for="(col, i1) in page.content.layout[0].children" :key="i1">
                    <div :class="col.class">
                        <template v-for="(el1, i2) in col.children" :key="i2">
                            <h3 v-if="el1.type == 'header-3'" :class="el1.class">{{ el1.title }}</h3>
                            <div v-else-if="el1.type == 'grid'" :class="el1.class">
                                <div v-for="(el2, i3) in el1.children" :key="i3" :class="el2.class">
                                    <template v-for="(el3, i4) in el2.children" :key="i4">
                                        <h3 v-if="el3.type == 'header-3'" :class="el3.class">{{ el3.title }}</h3>
                                        <router-link v-else-if="el3.type == 'category-button'" :class="`column-button category-button items-start ${$appState.estate.extra_data.button_type}`" :to="`/categories/${el3.data.slug}`">
                                            <img v-if="el3.data.extra_data?.icon_type === 'icon' && el3.data.extra_data?.icon" :src="`https://api.iconify.design/${iconUri(el3.data.extra_data?.icon, 0)}/${iconUri(el3.data.extra_data?.icon, 1)}.svg?color=%23${el3.data.extra_data.icon_color ?? '6366F1'}`" alt="">
                                            <img v-if="el3.data.extra_data?.icon_type === 'image' && el3.data.extra_data?.icon" :src="getIconImageUrl(el3.data.id)" alt="">
                                            <div class="button-wrapper">
                                                <h5>{{ el3.data.name }}</h5>
                                                <h6 v-if="el3.data.short_desc">{{ el3.data.short_desc }}</h6>
                                            </div>
                                        </router-link>
                                        <router-link v-else-if="el3.type == 'page-button'" :class="`column-button oage-button items-start ${$appState.estate.extra_data.button_type}`" :to="`/pages/${el3.data.slug}`">
                                            <img v-if="el3.data.content?.icon_type === 'icon' && el3.data.content?.icon" :src="`https://api.iconify.design/${iconUri(el3.data.content?.icon, 0)}/${iconUri(el3.data.content?.icon, 1)}.svg?color=%23${el3.data.content.icon_color ?? '6366F1'}`" alt="">
                                            <img v-if="el3.data.content?.icon_type === 'image' && el3.data.content?.icon" :src="getIconImageUrl(el3.data.id)" alt="">
                                            <div class="button-wrapper">
                                                <h5>{{ el3.data.name }}</h5>
                                            </div>
                                        </router-link>
                                    </template>
                                </div>
                            </div>
                            <div v-else-if="el1.type == 'category-feed'" :class="el1.class">
                                <template v-if="el1.loading">
                                    <div class="col-6 md:col-3" v-for="n in 8" :key="n">
                                        <Skeleton shape="rectangle" width="100%" height="15px" />
                                    </div>
                                </template>
                                <ul v-if="!el1.loading">
                                    <li v-for="(d, i5) in el1.documents" :key="i5" class="mb-3">
                                        <a v-if="!isPdf(d.mime_type) || (isPdf(d.mime_type) && canDownload(d.extra_data))" :href="downloadUrl(d.id)" target="_blank" class="block surface-card shadow-2 border-round p-3">
                                            <div class="flex flex-row font-bold mb-2">
                                                <iconify-icon :icon="documentService.getDocumentIcon(d.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <span>{{ d.title }}</span>
                                            </div>
                                            <p v-if="d.description" class="text-900">{{ d.description }}</p>
                                            <div class="text-500 text-sm text-right">{{formatDate(d.created_at)}}</div>
                                        </a>
                                        <a v-if="isPdf(d.mime_type) && !canDownload(d.extra_data)" :href="viewPdfUrl(d.id)" target="_blank" class="block surface-card shadow-2 border-round p-3">
                                            <div class="flex flex-row font-bold mb-2">
                                                <iconify-icon :icon="documentService.getDocumentIcon(d.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <span>{{ d.title }}</span>
                                            </div>
                                            <p v-if="d.description" class="text-900">{{ d.description }}</p>
                                            <div class="text-500 text-sm text-right">{{formatDate(d.created_at)}}</div>
                                        </a>
                                    </li>
                                    <li v-if="el1.documents.length < 1" class="font-bold"><i class="pi pi-angle-right mr-2"></i>Sin resultados</li>
                                </ul>
                                <router-link class="font-bold" :to="`/categories/${el1.data.slug}`">
                                    <img v-if="el1.data.extra_data?.icon_type === 'icon' && el1.data.extra_data?.icon" :src="`https://api.iconify.design/${iconUri(el1.data.extra_data?.icon, 0)}/${iconUri(el1.data.extra_data?.icon, 1)}.svg?color=%23${el1.data.extra_data.icon_color ?? '6366F1'}`" alt="">
                                    <img v-if="el1.data.extra_data?.icon_type === 'image' && el1.data.extra_data?.icon" :src="getIconImageUrl(el1.data.id)" alt="">
                                    Ver todos
                                </router-link>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
        <ComunidaeForm :layout="page?.content.layout" :pageId="page?.id" v-if="page?.content.page_type === 'form'" />
    </div>
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import PageService from '@/service/PageService.js';
import ApiService from '@/service/ApiService.js';
import DocumentService from '@/service/DocumentService.js';
import ErrorReportService from '@/service/ErrorReportService.js';
import ComunidaeForm from './ComunidaeForm.vue';

const compUrl = '/pages';

export default {
    beforeRouteEnter(to) {
        let goTo = RouteGuardService.isLoggedIn();

        if (goTo !== true) {
            window.sessionStorage.setItem('afterLogin', `${compUrl}/${to.params.slug}`);
        }

        return goTo;
    },
    data() {
        return {
            page: null,
            loading: true,
        }
    },
    pageService: null,
    documentService: null,
    errorReportService: null,
    pageContent: null,
    pageSlug: null,
    emits: ['updateBreadcrumbs'],
    created() {
        this.pageService = new PageService();
        this.documentService = new DocumentService();
        this.errorReportService = new ErrorReportService();

        if (!this.$route.params.slug) {
            this.$watch(
                () => this.$route.params,
                (params) => {
                    this.pageSlug = params.slug;
                },
            )
        } else {
            this.pageSlug = this.$route.params.slug;
        }
    },
    mounted() {
        this.pageContent = document.querySelector('.page-content');

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchPage();
                },
            );
        } else {
            this.fetchPage();
        }
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        fetchPage() {
            this.loading = true;

            this.pageService.fetchPageContent(this.$appState.estate.id, this.pageSlug).then(response => {
                if (response.data.page.content.page_type === 'basic') {
                    this.pageService.insertBasicContent(response.data.page.content.layout[0].content, this.pageContent);
                } else if (response.data.page.content.page_type === 'custom') {
                    if (response.data.page.content.layout[0].children && response.data.page.content.layout[0].children.length > 0) {
                        this.initContent(response.data.page.content.layout[0], response.data.page.page_links, response.data.page.category_links);
                    }
                    // this.pageService.createLayout(this.page.content.layout, this.pageContent, null, response.data.page.page_links, response.data.page.category_links);
                }

                this.page = response.data.page;

                this.loading = false;
                this.$emit('updateBreadcrumbs', {label: this.page.name, icon: this.page.content.icon, to: `${compUrl}/${this.pageSlug}`, replace: false});
            }).catch(error => {
                console.error(error);
                this.errorReportService.sendReport(
                    this.$appState.visitorId,
                    window.navigator.userAgent,
                    this.$appState.estate.id,
                    this.errorReportService.getRequestData(error.request),
                    this.errorReportService.getErrorData(error.response),
                );
                this.loading = false;
                this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido obtener la página', life: 3000});

                if (error.response && error.response.status === 401) {
                    RouteGuardService.logout();
                    window.sessionStorage.setItem('afterLogin', '/');
                    this.$router.replace(RouteGuardService.loginUrl);
                }
            });
        },
        initContent(layout, pages, categories) {
            layout.children.forEach(c => {
                if (c.type && c.type === 'page-button' && !c.data) {
                    c.data = pages.find(p => p.id === c.pageId);
                } else if (c.type && c.type === 'category-button' && !c.data) {
                    c.data = categories.find(ca => ca.id === c.categoryId);
                } else if (c.type && c.type === 'category-feed' && !c.data) {
                    c.data = categories.find(ca => ca.id === c.categoryId);
                    c.loading = true;
                    c.documents = [];

                    this.pageService.fetchCategoryFeed(this.$appState.estate.id, c.categoryId)
                        .then(response => {
                            c.documents = response.data.documents;
                            c.documents.forEach(document => document.created_at = new Date(document.created_at));
                            c.loading = false;
                            this.$forceUpdate();
                        })
                        .catch(error => {
                            console.error(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request),
                                this.errorReportService.getErrorData(error.response),
                            );
                            c.loading = false;
                            this.$forceUpdate();
                        });
                }

                if (c.children && c.children.length > 0) {
                    this.initContent(c, pages, categories);
                }
            });
        },
        iconUri(icon, index) {
            return icon.split(':')[index];
        },
        getIconImageUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/categories/${id}/icon-image?_token=${token}`;
        },
        downloadUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/documents/${id}/download?_token=${token}`;
        },
        viewPdfUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/documents/${id}/view-pdf?_token=${token}`;
        },
        isPdf(mime_type) {
            return mime_type === 'application/pdf';
        },
        canDownload(documentExtraData) {
            if (documentExtraData && documentExtraData.disallow_download) {
                return false;
            }

            return true;
        },
    },
    components: {
        'ComunidaeForm': ComunidaeForm
    }
}
</script>
<style scoped>
</style>