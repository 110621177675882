<template>
    <template v-for="(el, i) in layout" :key="i">
        <div class="field mb-4">
            <label :for="el.name">{{ el.label }}</label>
            <InputText type="text" v-if="el.type === 'input-text'" v-model="v$.form[el.name].$model" class="w-full mb-2" :class="{'p-invalid': v$.form[el.name].$error}" :required="el.required" />
            <InputText type="email" v-if="el.type === 'input-email'" v-model.trim="v$.form[el.name].$model" class="w-full mb-2" :class="{'p-invalid': v$.form[el.name].$error}" :required="el.required" />
            <InputMask :unmask="true" v-if="el.type === 'input-phone'" v-model.trim="v$.form[el.name].$model" mask="999 999 999" class="w-full mb-2" :class="{'p-invalid': v$.form[el.name].$error}" :required="el.required" />
            <Textarea v-if="el.type === 'textarea'" v-model="v$.form[el.name].$model" :autoResize="true" class="w-full mb-2" rows="8" :class="{'p-invalid': v$.form[el.name].$error}" :required="el.required" />
            <p v-if="el.type === 'file'"><strong>La suma del tamaño de todos los archivos no puede exceder los 25MB.</strong></p>
            <FileUpload v-if="el.type === 'file'" name="attachments[]" :customUpload="true" @uploader="fileChanged" @select="fileChanged" mode="advanced" :showUploadButton="false" chooseLabel="Buscar archivo" :accept="$appState.acceptedMimetypes" />
            <div v-for="error of v$.form[el.name]?.$errors" :key="error.$uid">
                <small class="p-error">{{ validationMessages[error.$validator] }}</small>
            </div>
        </div>
        <p v-if="el.type === 'paragraph'">{{ el.text }}</p>
    </template>
    <div class="mt-4" v-if="attachmentsError"><p class="p-error font-bold">{{ attachmentsError }}</p></div>
    <Button label="Enviar" icon="pi pi-send" :loading="working" class="p-button p-button-success mt-4" @click="sendForm" :disabled="canSendForm || working" />
    <div class="mt-4" v-if="sendError"><small class="p-error font-bold">{{ sendError }}</small></div>

    <Dialog v-model:visible="formSentDialog" :style="{width: '450px'}" header="Formulario enviado" :modal="true" class="p-fluid">
        <div class="confirmation-content">
            <i class="pi pi-info-circle mr-3" style="font-size: 2rem" />
            <span>El formulario se ha enviado correctamente.</span>
        </div>
        <template #footer>
            <Button label="Cerrar" icon="pi pi-check" class="p-button-text p-button-success" @click="formSentDialog = false" />
        </template>
    </Dialog>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ValidationMessages from '@/validationMessages';
import EstateService from '@/service/EstateService';
import ErrorReportService from '@/service/ErrorReportService.js';

const validInputs = [
    'input-text',
    'input-phone',
    'input-email',
    'textarea',
];

const maxAttachmentsSize = 26210000;

export default {
    props: {
        pageId: Number,
        layout: Array
    },
    setup() {

        return { v$: useVuelidate() };
    },
    validations() {
        let form = {};
        this.layout.forEach(el => {
            if (validInputs.includes(el.type) && el.required) {
                form[el.name] = { required };
            }
        });

        return {
            form
        };
    },
    data() {
        let form = {};

        this.layout.forEach(el => {
            if (validInputs.includes(el.type)) {
                form[el.name] = '';
            }
        });

        return {
            working: false,
            validationMessages: {},
            sendError: null,
            attachmentsError: null,
            formSentDialog: false,
            form
        };
    },
    estateService: null,
    errorReportService: null,
    selectedFiles: [],
    created() {
        this.estateService = new EstateService();
        this.errorReportService = new ErrorReportService();
        this.validationMessages = ValidationMessages;
    },
    computed: {
        canSendForm() {
            let can = false;

            this.layout.forEach(el => {
                if (validInputs.includes(el.type)) {
                    if (this.v$.form[el.name]?.$errors.length !== 0 || this.form[el.name].length === 0) {
                        can = true;
                    }
                }
            });

            if (this.selectedFiles && this.selectedFiles.length > 0) {
                let totalSize = 0;

                this.selectedFiles.forEach(f => {
                    totalSize += f.size;
                });

                if (totalSize > maxAttachmentsSize) {
                    can = true;
                }
            }

            return can;
        },
    },
    methods: {
        sendForm() {
            if (!this.working) {
                this.working = true;
                this.sendError = null;
                let fd = new FormData();
                let formFields = {};

                this.layout.forEach(el => {
                    if (validInputs.includes(el.type)) {
                        fd.append(el.name, this.form[el.name]);
                        formFields[el.name] = this.form[el.name];
                    }
                });

                if (this.selectedFiles && this.selectedFiles.length > 0) {
                    this.selectedFiles.forEach((f, i) => {
                        fd.append(`attachments[${i}]`, f);
                    });
                }

                this.estateService.sendForm(this.$appState.estate.id, this.pageId, fd)
                    .then(response => {
                        this.working = false;

                        if (response.data.sent === true) {
                            this.formSentDialog = true;
                        } else {
                            this.sendError = 'No se ha podido enviar el formulario.';
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.errorReportService.sendReport(
                            this.$appState.visitorId,
                            window.navigator.userAgent,
                            this.$appState.estate.id,
                            this.errorReportService.getRequestData(error.request, {
                                ...formFields,
                                files: this.selectedFiles.map(f => ({file_name: f.name, file_size: f.size}))
                            }),
                            this.errorReportService.getErrorData(error.response),
                        );
                        this.working = false;
                        this.sendError = 'No se ha podido enviar el formulario.';
                    });
            }
        },
        fileChanged(ev) {
            this.selectedFiles = ev.files;
            this.attachmentsError = null;
            let totalSize = 0;

            this.selectedFiles.forEach(f => {
                totalSize += f.size;
            });

            console.log(totalSize);

            if (totalSize > maxAttachmentsSize) {
                this.attachmentsError = 'El tamaño total de los archivos supera los 25MB.';
            }
        }
    }
};
</script>