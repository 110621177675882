import ApiService from './ApiService';
import HelperFunctions from '../helperFunctions';
import axios from 'axios';

const iconifyApi = 'https://api.iconify.design/';

export default class PageService {
    fetchPages(estateId, withTrashed, query) {
        if (withTrashed) {
            return axios.get(`${ApiService.getHost()}/pages?withTrashed=true&q=${query}&estate_id=${estateId}`, {
                headers: ApiService.getHeaders()
            });
        } else {
            return axios.get(`${ApiService.getHost()}/pages?q=${query}&estate_id=${estateId}`, {
                headers: ApiService.getHeaders()
            });
        }
    }

    deletePage(pageId) {
        return axios.delete(`${ApiService.getHost()}/pages/${pageId}`, {
            headers: ApiService.getHeaders()
        });
    }

    restorePage(pageId) {
        return axios.get(`${ApiService.getHost()}/pages/${pageId}/restore`, {
            headers: ApiService.getHeaders()
        });
    }

    createPage(estateId, name, slug, content) {
        return axios.post(`${ApiService.getHost()}/pages`, {
            estate_id: estateId,
            name: name,
            slug: slug,
            content
        }, {
            headers: ApiService.getHeaders()
        });
    }

    fetchPage(pageId) {
        return axios.get(`${ApiService.getHost()}/pages/${pageId}`, {
            headers: ApiService.getHeaders()
        });
    }

    updatePage(pageId, name, slug, content) {
        return axios.put(`${ApiService.getHost()}/pages/${pageId}`, {
            name: name,
            slug: slug,
            content
        }, {
            headers: ApiService.getHeaders()
        });
    }

    createLayout(layout, parentNode, estateId, pages, categories, buttonType) {
        layout.forEach(child => {
            let origin;
            let newNode;
            let tmpNode;
            let tmpNode2;

            switch (child.type) {
                case 'grid':
                case 'column':
                    newNode = document.createElement('div');
                    break;
                case 'header-3':
                    newNode = document.createElement('h3');
                    newNode.innerText = child.title;
                    break;
                case 'category-button':
                case 'page-button':
                    if (child.type == 'category-button')
                        origin = categories.find(c => c.id == child.categoryId);
                    else
                        origin = pages.find(p => p.id == child.pageId);

                    if (origin) {
                        newNode = document.createElement('a');
                        newNode.setAttribute('href', origin.link);

                        if (origin.content && origin.content.icon_type == 'icon' && origin.content.icon) {
                            let iconNode = document.createElement('img');
                            let icon = origin.content.icon.split(':');
                            iconNode.setAttribute('src', `${iconifyApi}${icon[0]}/${icon[1]}.svg?color=%236366F1`);

                            if (buttonType === 'button-type-2') {
                                iconNode.setAttribute('style', 'height: 3rem;');
                            } else {
                                iconNode.setAttribute('style', 'height: 1.6rem;');
                            }

                            newNode.append(iconNode);
                        } else if (origin.extra_data && origin.extra_data.icon_type == 'icon' && origin.extra_data.icon) {
                            let iconNode = document.createElement('img');
                            let icon = origin.extra_data.icon.split(':');
                            iconNode.setAttribute('src', `${iconifyApi}${icon[0]}/${icon[1]}.svg?color=%236366F1`);
                            
                            if (buttonType === 'button-type-2') {
                                iconNode.setAttribute('style', 'height: 3rem;');
                            } else {
                                iconNode.setAttribute('style', 'height: 1.6rem;');
                            }

                            newNode.append(iconNode);
                        }
    
                        let wrapperNode = document.createElement('div');
                        wrapperNode.setAttribute('class', 'button-wrapper');
                        let titleNode = document.createElement('h5');
                        titleNode.innerText = origin.name;
                        wrapperNode.append(titleNode);

                        if (origin.short_desc) {
                            let subtitleNode = document.createElement('h6');
                            subtitleNode.innerText = origin.short_desc;
                            wrapperNode.append(subtitleNode);
                        }

                        newNode.append(wrapperNode);
                    }
                    break;
                case 'category-feed':
                    origin = categories.find(c => c.id == child.categoryId);

                    if (origin) {
                        newNode = document.createElement('div');

                        tmpNode = document.createElement('ul');
                        this.createCategoryFeedLayout(estateId, child.categoryId, tmpNode);
                        newNode.append(tmpNode);

                        tmpNode = document.createElement('a');
                        tmpNode2 = document.createElement('img');
                        let icon = origin.extra_data.icon.split(':');
                        tmpNode2.setAttribute('src', `${iconifyApi}${icon[0]}/${icon[1]}.svg?color=%236366F1`);
                        tmpNode2.setAttribute('class', 'mr-2');
                        tmpNode.innerText = 'Ver todos';
                        tmpNode.setAttribute('href', origin.link);
                        tmpNode.setAttribute('class', 'font-bold');
                        tmpNode.prepend(tmpNode2);
                        newNode.append(tmpNode);
                    }
                    break;
            }

            if (newNode) {
                if (child.type === 'category-button' || child.type === 'page-button') {
                    newNode.setAttribute('class', `${child.class} ${buttonType}`);
                } else {
                    newNode.setAttribute('class', child.class);
                }

                if (child.children) {
                    this.createLayout(child.children, newNode, estateId, pages, categories, buttonType);
                }

                if ((child.type == 'grid' || child.type == 'column') && newNode.children.length > 0) {
                    parentNode.append(newNode);
                } else if (child.type != 'grid' && child.type != 'column') {
                    parentNode.append(newNode);
                }
            }
        });
    }

    createCategoryFeedLayout(estateId, categoryId, feedNode) {
        this.fetchCategoryFeed(estateId, categoryId).then(response => {
            if (response.data.documents && response.data.documents.length > 0) {
                response.data.documents.forEach(d => {
                    let liNode = document.createElement('li');
                    let aNode = document.createElement('a');
                    let iNode = document.createElement('img');
                    let icon = HelperFunctions.getDocumentIcon(d.mime_type).split(':');
                    liNode.setAttribute('class', 'mb-3');
                    iNode.setAttribute('src', `${iconifyApi}${icon[0]}/${icon[1]}.svg?color=%236366F1`);
                    iNode.setAttribute('style', 'height: 1.8em;');
                    iNode.setAttribute('class', 'mr-2');
                    aNode.innerText = d.title;
                    aNode.setAttribute('href', ApiService.getDocumentDownloadUrl(d.id));
                    aNode.setAttribute('target', '_blank');
                    aNode.setAttribute('class', 'flex flex-row font-bold');
                    aNode.prepend(iNode);
                    liNode.append(aNode);
                    feedNode.append(liNode);
                });
            } else {
                let liNode = document.createElement('li');
                liNode.setAttribute('class', 'font-bold');
                liNode.innerText = 'Sin resultados';
                let iNode = document.createElement('i');
                iNode.setAttribute('class', 'pi pi-angle-right mr-2');
                liNode.prepend(iNode);
                feedNode.append(liNode);
            }
        });
    }

    insertBasicContent(content, parentNode) {
        let newNode = document.createElement('div');
        newNode.setAttribute('class', 'basic-page-content');
        newNode.innerHTML = content;

        parentNode.append(newNode);
    }

    fetchCategoryFeed(estateId, categoryId, orderBy, sortOrder) {
        return axios.get(`${ApiService.getHost()}/estates/${estateId}/categories/${categoryId}/feed?ob=${orderBy}&so=${sortOrder}`, {
            headers: ApiService.getHeaders()
        });
    }

    fetchPageContent(estateId, pageSlug) {
        return axios.get(`${ApiService.getHost()}/estates/${estateId}/pages/${pageSlug}`, {
            headers: ApiService.getHeaders()
        });
    }

    fetchFooterPageContent(estateId, pageSlug) {
        return axios.get(`${ApiService.getHost()}/estates/${estateId}/footer-links/${pageSlug}`, {
            headers: ApiService.getHeaders()
        });
    }
}